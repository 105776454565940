import React from 'react'
import { Component } from 'react'
import axios from 'axios'

import './index.css'

class Contact extends Component{

  state = {name: '', email: '', message: ''}

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }
  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }
  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"https://gcs-deploy-node.onrender.com/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: '', email: '', message: ''})
  }
  render(){
    return(
      <div className='contact-section'>
        <h3 className='contact-me'>Contact Me</h3>
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
              <label className='form-label' htmlFor="name">Name</label>
              <input type="text" className="form-control text" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
          </div>
          <div className="form-group">
              <label className='form-label' htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control text" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
          </div>
          <div className="form-group">
              <label className='form-label' htmlFor="message">Message</label>
              <textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
    )
  }
}

export default Contact